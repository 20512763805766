import React from 'react';
import classnames from 'classnames';
import { Menu } from '@mui/material';
import FontAwesome from 'react-fontawesome';
import { useSelector } from 'react-redux';
import { AMAZON_BASE_URL, HEADER_ID, MENUS } from './Constants';
import Anchor from './Anchor';
import { LunchMenuType, Store } from './types';

const getHeaderClassName = (): string => (window.scrollY > 0 ? 'scrolled' : '');

const Header: React.FC = () => {
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    const headerRef = React.useRef<HTMLDivElement>();
    const [selectedMenu, setSelectedMenu] = React.useState('');
    const [className, setClassName] = React.useState(getHeaderClassName());
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const scrollHandler = React.useCallback(() => {
        setClassName(getHeaderClassName());

        const headerHeight = headerRef.current?.offsetHeight;
        const fromTop = window.pageYOffset + 1 + headerHeight;

        const id = Object.values(MENUS).reduce(
            (acc, { hash }) =>
                document.getElementById(hash)?.offsetTop < fromTop ? hash : acc,
            ''
        );

        setSelectedMenu(id);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    React.useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler]);

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleScrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleAnchorClick = React.useCallback((id: string) => {
        setAnchorEl(null);
        setSelectedMenu(id);
    }, []);

    const menus = Object.values(MENUS)
        .filter(({ ignore }) => !ignore)
        .sort((a, b) => a.order - b.order)
        .map(({ hash, title }) => (
            <Anchor
                key={hash}
                hash={hash}
                className={classnames('header-link', hash, {
                    active: selectedMenu === hash
                })}
                onClick={handleAnchorClick}>
                {title}
            </Anchor>
        ));

    if (!storeConfiguration) {
        return null;
    }

    if (
        [LunchMenuType.DAILY, LunchMenuType.FIXED].includes(
            storeConfiguration.lunchMenuType
        ) &&
        storeConfiguration.lunchMenuFileName
    ) {
        menus.unshift(
            <a
                href={`${AMAZON_BASE_URL}/stores/store___${storeConfiguration.shortUniqueID}/downloads/${storeConfiguration.lunchMenuFileName}`}
                className='header-link menu-link'
                target='_blank'
                rel='noreferrer'
                key='lunch-menu'>
                --MITTAGSMENU--
            </a>
        );
    }

    return (
        <div className={`header ${className}`} ref={headerRef} id={HEADER_ID}>
            <div className='title' onClick={handleScrollToTop}>
                Grieks Restaurant Vasileia
            </div>
            <div className='links'>{menus}</div>
            <div className='dropdown-menu'>
                <FontAwesome
                    id='menu-dropdown'
                    aria-controls='menu'
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    name='bars'
                    className='menu-icon'
                    onClick={handleClick}
                />
                <Menu
                    id='menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'menu-dropdown' }}>
                    {menus}
                </Menu>
            </div>
        </div>
    );
};

export default Header;
