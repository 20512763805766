import moment from 'moment-timezone';
import { Closure } from './types';

export const MENUS: Record<
    string,
    {
        hash: string;
        title?: string;
        order?: number;
        ignore?: boolean;
    }
> = {
    ABOUT_US: { hash: 'about-us', title: 'OVER ONS', order: 1 },
    CONTACT: { hash: 'contact', title: 'CONTACT', order: 7 },
    DRINKS: { hash: 'dinerkaart', title: 'DINERKAART', order: 3 },
    DESSERTS: { hash: 'dessertkaart', title: 'DESSERTKAART', order: 4 },
    // FOOD: { hash: 'lunchkaart', title: 'LUNCHKAART', order: 2 },
    GALLERY: { hash: 'gallery', title: 'GALERIJ', order: 6 },
    IMPRESSUM: { hash: 'impressum', title: 'IMPRINT', order: 8 },
    RESERVATION: { hash: 'reservation', title: 'RESERVEREN', order: 5 }
};

export const HEADER_ID = 'header';

export const AMAZON_BASE_URL = 'https://d1oxmbo269r15r.cloudfront.net/stores';

export const BASE_URL =
    'https://personalizedgenericapi.azurewebsites.net/api/Restaurant';

export const SAO_BASE_URL = 'https://backbonedev.scanandorder.eu/api';

export const required = (value: string) => (value ? undefined : 'Verplicht Veld');

export const getUpdatedClosure = (closure: Closure<string>): Closure => ({
    ...closure,
    effectiveByDate: moment(closure.effectiveByDate),
    endDate: moment(closure.endDate),
    startDate: moment(closure.startDate)
});
