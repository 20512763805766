import { useSelector } from 'react-redux';
import { AMAZON_BASE_URL, MENUS } from './Constants';
import TitleSection from './TitleSection';
import { Store } from './types';

const AboutUs = () => {
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div
            id={MENUS.ABOUT_US.hash}
            className='about-us-section'
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/about_bg.png")`
            }}>
            <div className='container'>
                <TitleSection title='Over ons' />
                <div className='content'>
                    <div className='description'>
                        <p>
                            Als team Grieks Restaurant Vasileia willen wij u van harte
                            welkom heten in ons restaurant! Onze wens was altijd al om
                            ooit onze eigen plek te openen, waarin wij u als gast kennis
                            kunnen laten maken met de Griekse lokale keuken.
                        </p>
                        <p>
                            Met liefde en passie voor traditionele recepten en aroma's
                            van het Griekse land, alsmede respect voor onze tradities,
                            hebben we het menu van ons restaurant gemaakt.
                            <br />
                            Door de diversiteit van ingrediënten en smaken in onze
                            gerechten, waant u zich in een reis door Griekenland.
                            <br />
                            <br />
                            Eet smakelijk, oftewel Kali orexi!
                            <br />
                            <br />
                            Met hartelijke groet,
                            <br />
                            <br />
                            Grieks Restaurant Vasileia
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
