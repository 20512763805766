import React from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import FontAwesome from 'react-fontawesome';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Reservation from './Reservation';
import Menu from './Menu';
import { AMAZON_BASE_URL, MENUS } from './Constants';
import { fetchData } from './actions/Actions';
import { Store } from './types';
import Gallery from './Gallery';

const App: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>();
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);
    const closures = useSelector(({ app }: Store) => app.closures);
    const dispatch = useDispatch();

    const currentClosures = React.useMemo(
        () =>
            closures.filter(({ effectiveByDate, endDate }) =>
                moment().isBetween(effectiveByDate, endDate, 'day', '[]')
            ),
        [closures]
    );

    React.useEffect(() => {
        dispatch(fetchData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (currentClosures.length) {
            setOpen(true);
        }
    }, [currentClosures.length]);

    const handleModalClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div className='app-body'>
            {open && (
                <Modal
                    open={open}
                    onClose={handleModalClose}
                    classes={{ root: 'modal' }}>
                    <div className='vacation'>
                        <h2>
                            <FontAwesome name='times' onClick={handleModalClose} />
                        </h2>
                        {currentClosures.map(({ id, closureNotification }, i) => (
                            <React.Fragment key={id}>
                                <div className='closure-message'>
                                    {closureNotification}
                                </div>
                                {i !== currentClosures.length - 1 &&
                                currentClosures.length > 1 ? (
                                    <hr />
                                ) : null}
                            </React.Fragment>
                        ))}
                    </div>
                </Modal>
            )}
            <Header />
            <div
                className='fill-image'
                style={{
                    backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/general/main.jpg")`
                }}
            />
            <AboutUs />
            <Menu
                title='Dinerkaart'
                hash={MENUS.DRINKS.hash}
                backgroundImage={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/drinks_bg.jpg`}
                categories={[]}
                link='Menu_card_current.pdf'
                text='Dinerkaart'
            />
            <Menu
                title='Dessertkaart'
                hash={MENUS.DESSERTS.hash}
                backgroundImage={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/desserts.jpg`}
                categories={[]}
                link='Dessert_card_current.pdf'
                text='Dessertkaart'
            />
            <Reservation />
            <Gallery />
            <Contact />
            <Footer />
        </div>
    );
};

export default App;
