import React from 'react';
import { Form, Field, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { FormApi } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { AMAZON_BASE_URL, MENUS, required } from './Constants';
import * as Api from './Api';
import { Store } from './types';
import { enqueueNotification } from './actions/Actions';
import TitleSection from './TitleSection';

type FormValues = {
    email: string;
    fullName: string;
};

const Footer: React.FC = () => {
    const dispatch = useDispatch();
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    const subscribeToNewsletter = React.useCallback(
        (values: FormValues, form: FormApi) =>
            Api.registerToNewsletter(values).then(res => {
                if (res.status === 200) {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message:
                                '✓ Je hebt je succesvol aangemeld voor onze nieuwsbrief.',
                            options: {
                                variant: 'success'
                            }
                        })
                    );

                    form.reset({});
                    ['email', 'fullName'].forEach(key => {
                        form.resetFieldState(key);
                    });
                } else {
                    res.text().then(err => {
                        dispatch(
                            enqueueNotification({
                                key: new Date().getTime(),
                                message: `Er treedt een fout op... Code: ${res.status}${
                                    err ? `, Bericht: ${err}` : ''
                                }`,
                                options: {
                                    variant: 'error'
                                }
                            })
                        );
                    });
                }
            }),
        [dispatch]
    );

    const renderForm = React.useCallback(
        ({ handleSubmit, submitting, validating }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='newsletter-form form'>
                <Field name='fullName' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <input {...input} placeholder='Naam' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <Field name='email' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <input {...input} placeholder='Email' type='email' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <button type='submit' disabled={submitting || validating}>
                    <FontAwesome name='envelope-o' className='email' />
                </button>
            </form>
        ),
        []
    );

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div
            id={MENUS.IMPRESSUM.hash}
            className='footer'
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/newsletter_bg.png")`
            }}>
            <div className='newletter'>
                <div className='container'>
                    <TitleSection title='Newsletter' />
                    <div className='form'>
                        <Form render={renderForm} onSubmit={subscribeToNewsletter} />
                        <div className='social-media'>
                            <a
                                href='https://www.facebook.com/profile.php?id=100092409134037'
                                target='_blank'
                                rel='noreferrer'>
                                <FontAwesome
                                    name='facebook'
                                    className='link facebook'
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-info'>
                <p>
                    Grieks Restaurant Vasileia
                    <br />
                    Evangelia Gkiourou
                    <br />
                    Eindsestraat 2, Dongen 5105 AC
                    <br />
                    Nr: {storeConfiguration.reservationsPhone1} - Email:{' '}
                    <a href={`mailto:${storeConfiguration.reservationsEmail}`}>
                        {storeConfiguration.reservationsEmail}
                    </a>
                    <br />
                    UID-Nr: NL004704176B79
                    <br />
                    <br />© Grieks Restaurant Vasileia {moment().year()}. All rights
                    reserved. Design & Development:{' '}
                    <a href='http://personalized.at' target='_blank' rel='noreferrer'>
                        Personalized.at
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default Footer;
