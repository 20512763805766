import React from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { FormApi } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { AMAZON_BASE_URL, MENUS, required } from './Constants';
import * as Api from './Api';
import { Store } from './types';
import { enqueueNotification } from './actions/Actions';

type FormValues = {
    name: string;
    email: string;
    message: string;
};

const Contact: React.FC = () => {
    const dispatch = useDispatch();
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    const handleContact = React.useCallback(
        (values: FormValues, form: FormApi) => {
            Api.contact(values)
                .then(() => {
                    form.reset();

                    ['name', 'email', 'message'].forEach(key => {
                        form.resetFieldState(key);
                    });

                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: 'Uw bericht is succesvol verzonden',
                            options: { variant: 'success' }
                        })
                    );
                })
                .catch(err => {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: err.message,
                            options: { variant: 'error' }
                        })
                    );
                });
        },
        [dispatch]
    );

    const renderContactForm = React.useCallback(
        ({ handleSubmit, submitting, validating }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='contact-form form'>
                <div className='one-line'>
                    <Field name='name' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Naam' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='email' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Email' type='email' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </div>
                <Field name='message' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <textarea {...input} placeholder='Tekst' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <button type='submit' disabled={submitting || validating}>
                    Neem contact met ons op
                </button>
            </form>
        ),
        []
    );

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div
            id={MENUS.CONTACT.hash}
            className='contact-section'
            style={{
                backgroundImage: `url("${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/general/main_withoutLogo.jpg")`
            }}>
            <div className='wrapper'>
                <div className='contact-container'>
                    <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d309.65125147681096!2d4.959015273684019!3d51.61936200720616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6979d36b59d89%3A0x1ca5237de2df702f!2sEindsestraat%202%2C%205105%20AC%20Dongen%2C%20Netherlands!5e0!3m2!1sen!2sgr!4v1690311164478!5m2!1sen!2sgr'
                        width='570'
                        height='400'
                        style={{ border: 0 }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                        title='map'
                    />
                    <div className='contact-info'>
                        <div className='title-section'>
                            <h1>Contact</h1>
                            <Form render={renderContactForm} onSubmit={handleContact} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
