import { RouterState } from 'connected-react-router';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export interface Category {
    id: number;
    name: string;
    productCategoryMiscInfo: {
        id: number;
        isDailyMenu: boolean;
        isDrinks: boolean;
        isFood: boolean;
        isWine: boolean;
    };
}

export interface Product {
    id: number;
    description: string;
    isVegan: boolean;
    isVegetarian: boolean;
    name: string;
    price: number;
    productAllergens: { allergenID: number }[];
    productCategoryID: number;
    productVarieties: {
        id: number;
        name: string;
        price: number;
        isDefault: boolean;
    }[];
}

export interface Allergen {
    id: number;
    code: string;
}

export enum LunchMenuType {
    NO_MENU = 1,
    DAILY = 2,
    FIXED = 3
}

interface StoreConfiguration {
    licenceID: number;
    defaultAllowedPeoplePerTable: number;
    canUserPayWithCard: boolean;
    isCardReaderMobile: boolean;
    cardReaderAmount: number;
    providesFreeWifi: boolean;
    hasOrderGeolocationActivated: boolean;
    hasOrder2FaAuthenticationEnabled: boolean;
    hasConfiguredLogo: boolean;
    shortUniqueID: string;
    paysMonthly: boolean;
    storeName: string;
    id: number;
    timestamp: string;
    selectedLanguages: {
        languageID: number;
        timestamp: string;
        id: number;
    }[];
    lunchMenuFileName: string;
    lunchMenuType: LunchMenuType;
    requiresReservationStoreConfirmation: boolean;
    reservationsEmail: string;
    reservationsPhone1: string;
    reservationsPhone2: string;
    supportsReservationsFromWebsite: boolean;
}

export type Closure<T extends string | moment.Moment = moment.Moment> = {
    closureNotification: string;
    effectiveByDate: T;
    startDate: T;
    endDate: T;
    id: number;
    storeID: number;
    shortDescription: string;
};

export interface AppStore {
    notifications: INotification[];
    categories: Category[];
    products: Product[];
    allergens: Allergen[];
    galleryImages: { name: string; id: number }[];
    storeConfiguration: StoreConfiguration;
    closures: Closure[];
}

export interface Store {
    router?: RouterState;
    app: AppStore;
}

export interface INotification {
    message: SnackbarMessage;
    options: OptionsObject;
    key: SnackbarKey;
}
